import host from '@/assets/script/host'
// 构建中心 相关接口
const build = {
  application: {
    list: `${host}/bizmeta/application/list`,
    detail: `${host}/bizmeta/application/detail`,
    create: `${host}/bizmeta/application/save`,
    update: `${host}/bizmeta/application/update`,
    active: `${host}/bizmeta/application/active`,
    delete: `${host}/bizmeta/application/delete`,
    sort: `${host}/bizmeta/application/update/sort`,
    copy: `${host}/bizmeta/v1/object/describe/service/copyApp`,

    getApp(appId){ return `${host}/bizmeta/application/getApp/${appId}` },
    updateDomainStatus: `${host}/bizmeta/application/updateDomainStatus`,
    updateDomain: `${host}/bizmeta/application/updateDomain`,
    getAppIdByDomain: `${host}/bizmeta/application/getAppIdByDomain`,
  },
  section: {
    create(appId){ return `${host}/bizmeta/${appId}/section/save` },
    update(appId){ return `${host}/bizmeta/${appId}/section/update` },
    delete(appId){ return `${host}/bizmeta/${appId}/section/delete` },
    sort(appId){ return `${host}/bizmeta/${appId}/section/update/sort`},
  }, 
  business: {
    create: `${host}/bizmeta/v1/object/describe/service/createDescribe`, 
    update: `${host}/bizmeta/v1/object/describe/service/updateDescribe`, 
    detail: `${host}/bizmeta/v1/object/describe/service/detailDescribe`, 
    updateName: `${host}/bizmeta/v1/object/describe/service/updateDesName`,
    move(appId){ return `${host}/bizmeta/application/${appId}/move` },
    updateInfo(appId, type){ return `${host}/bizmeta/application/${appId}/${type}/update` },

    active: `${host}/bizmeta/v1/object/describe/service/activeDescribe`, 
    // delete: `${host}/bizmeta/v1/object/describe/service/deleteDescribe`,
    delete: `${host}/bizmeta/v1/object/describe/service/removeDescribe`,
    generateTemplate: `${host}/bizmeta/v1/object/bulkimport/generateTemplate`,
    exportTemplate(apiName){
      return `${host}/bizmeta/v1/object/${apiName}/action/Export`
    },
    printData(apiName){
      return `${host}/bizmeta/v1/object/print/${apiName}/action/pdf`
    },
    printTableData(apiName){
      return `${host}/bizmeta/v1/object/print/${apiName}/action/tablePdf`
    },
    copySource(appId, apiName){
      return `${host}/bizmeta/v1/app/${appId}/page/${apiName}/copy`
    },
    copyDescribe: `${host}/bizmeta/v1/object/describe/service/copyDescribe`,
    copySmartForm: `${host}/bizmeta/v1/object/describe/service/copySmartForm`,
    // 权限组
    authgroupList(appId, apiName){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/list`
    },
    authgroupUpdateMember(appId, apiName){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/updateMembers`
    },
    authgroupCreate(appId, apiName){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/create`
    },
    authgroupUpdate(appId, apiName){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/update`
    },
    authgroupDelete(appId, apiName){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/remove`
    },
    authgroupConfig(appId, apiName, groupId){
      return `${host}/bizmeta/admin/app/${appId}/object/${apiName}/authgroup/${groupId}/getconfig`
    },
    // 从模板 权限组
    authgroupListPro(appId, apiName, dataId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/list`
    },
    authgroupUpdateMemberPro(appId, apiName, dataId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/updateMembers`
    },
    authgroupCreatePro(appId, apiName, dataId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/create`
    },
    authgroupUpdatePro(appId, apiName, dataId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/update`
    },
    authgroupDeletePro(appId, apiName, dataId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/remove`
    },
    authgroupConfigPro(appId, apiName, dataId, groupId){
      return `${host}/bizmeta/project/${appId}/${apiName}/${dataId}/authgroup/${groupId}/getconfig`
    },
    entryAuthSet(appId){
      return `${host}/bizmeta/admin/app/${appId}/entryauth/set`
    },
    objAuthGet(appId){
      return `${host}/bizmeta/admin/app/${appId}/objauth/get`
    },
    // 关联模板列表
    // regionList: `${host}/bizmeta/v1/object/describe/service/findDesByRegion`, 
    // 模板描述 信息（不含layout）
    detailFields: `${host}/bizmeta/v1/object/describe/service/getDescribeByApiName`,
    // 模板描述及关联 信息（不含layout）
    detailFieldsAndRel: `${host}/bizmeta/v1/object/describe/service/getDesAndRelByApiName`,
    //选择数据源
    dataSource(appId) { return `${host}/bizmeta/application/${appId}/dataSource` },
    // 校验统计字段配置接口
    rollupFormula: `${host}/bizmeta/v1/object/calculate/service/validateRollup`,
    ocrTemplates: `${host}/bizmeta/plugin/ocr/rule/list`,
    ocrDetail: `${host}/bizmeta/plugin/ocr/rule/detail`,
    smsTemplates: `${host}/msg/smsTemplate/page`


  },


  extend: {
    update: `${host}/bizmeta/objectDescribe/ext/update`,
  },
  customBtn: {
    list: `${host}/bizmeta/v1/object/button/service/findButtonList`,
    validateAdd: `${host}/bizmeta/v1/object/button/service/validateButtonCount`,
    create: `${host}/bizmeta/v1/object/button/service/create`,
    update: `${host}/bizmeta/v1/object/button/service/update`,
    detail: `${host}/bizmeta/v1/object/button/service/findButtonInfo`,
    delete: `${host}/bizmeta/v1/object/button/service/delete`,
    active: `${host}/bizmeta/v1/object/button/service/changeButtonStatus`,
  },
  print: {
    list: `${host}/bizmeta/printTemplate/list`,
    create: `${host}/bizmeta/printTemplate/create`,
    update(id) { return `${host}/bizmeta/printTemplate/${id}/update` },
    delete: `${host}/bizmeta/printTemplate/del`,
  },
  dashboard: {
    create(appId){ return `${host}/bizmeta/v1/app/${appId}/page/create` } ,
    update(appId, pageApiName){ return `${host}/bizmeta/v1/app/${appId}/page/${pageApiName}/update` },
    detail(appId, pageApiName){ return `${host}/bizmeta/v1/app/${appId}/page/${pageApiName}/details` } ,
    delete(appId, pageApiName){ return `${host}/bizmeta/v1/app/${appId}/page/${pageApiName}/remove` } ,
    updateName(appId, pageApiName){ return `${host}/bizmeta/v1/app/${appId}/page/${pageApiName}/updateName` },
    preview(pageApiName, compApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/preview` },
    previewList(pageApiName, compApiName) {return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/detailTable/preview` },
    statisticsList(pageApiName, compApiName) {return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/detailTable/statistics` },
    exportStatisticsList(pageApiName, compApiName) {return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/detailTable/export` },
    statistics(pageApiName, compApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${compApiName}/statistics` },
    
    distinctFieldValues: `${host}/bizmeta/v1/page/distinctFieldValues`
  },
  reports: {
    chart: {
      create(pageApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/create` },
      update(pageApiName, chartApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${chartApiName}/update` },
      statistics(pageApiName, chartApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${chartApiName}/statistics` },
      preview(pageApiName, chartApiName){ return `${host}/bizmeta/v1/page/${pageApiName}/component/${chartApiName}/preview` }
    }    
  },
  workflow: {
    list: `${host}/bpm/template/view`,
    detail: `${host}/bpm/manage/template/info`,
    create: `${host}/bpm/template/commit`,
    update: `${host}/bpm/template/update`,
    delete: `${host}/bpm/template/delete`,
    enable: `${host}/bpm/template/operate`
  },
  automate: {
    list: `${host}/automate/automate/rule/list`,
    create: `${host}/automate/automate/rule/create`,
    edit: `${host}/automate/automate/rule/edit`,
    detail: `${host}/automate/automate/rule/detail`,
    delete: `${host}/automate/automate/rule/delete`,

    checkTemplate: `${host}/automate/automate/rule/check/level_loop`,

    nextExecTime: `${host}/automate/automate/trigger/timer/nextExecTime`,
    
    logList: `${host}/automate/automate/execlog/list`,
  }
}

export default build
